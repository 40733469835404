<template>
  <div class="content">
    <div class="main-content">
      <div class="top-href">
        <a href="#infoExpress">快递单发件人信息</a>
        <a href="#infoInvoice">发货单发件人信息</a>
        <a href="#order">订单显示顺序</a>
        <!-- <a href="#wordFilter" class="hidden">基础过滤词设置</a> -->
      </div>
      <div class="info-form" style="padding-bottom: 40px">
        <div
          class="info-head"
          style="border-bottom: 1px solid gray; height: 43px; line-height: 43px"
        >
          <div style="display: flex; justify-content: space-between">
            <div>
              <a name="infoExpress">快递单发件人信息</a>
              <span>此信息打印在快递单左侧</span>
            </div>
           
          </div>
        
        </div>

        <div
          style="position: relative"
          v-for="(item, index) in express_list"
          :key="index"
        >
        <span style="position: absolute;color: #F68B1E;top: 8px;left: 10px;">{{  index + 1}}、</span>
          <el-form
            :ref="'expresslistFrom_'+index"
            :model="item"
            :rules="rule"
            label-position="left"
            inline-message
            @submit.native.prevent
            style="disply: flex; flex-direction: column"
          >
            <span v-if="checked" class="numClass">{{ index + 1 }}、</span>
            <el-form-item style="margin-bottom: 0px">
              <div class="info-tb" style="display: flex">
                <el-form-item
                  label="发件人"
                  prop="name"
                  style="display: flex; margin-buttom: 0"
                >
                  <el-input
                    size="mini"
                    v-model="item.name"
                    :maxlength="11"
                    class="info-input"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="电话"
                  prop="phone"
                  
                  style="display: flex; margin: auto 50px"
                >
                  <el-input
                    size="mini"
                    v-model="item.phone"
                    :maxlength="11"
                    class="info-input"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form-item>

            
            <!-- <p
              @click="deleteBtn(index)"
              style="
                font-size: 14px;
                margin-bottom: 20px;
                margin-left: 575px;
                color: blue;
                text-decoration: underline;
                cursor: pointer;
                width:30px;
              "
            >
              删除
            </p> -->
          </el-form>
        </div>
        <div class="info-tb">
          <div style="display: flex; align-items: center">
            <el-button
              type="primary"
              size="mini"
              @click="saveExpressSenderList"
              style="margin: auto 40px; margin-right: 10px"
              >保存</el-button
            >
            <!-- <el-checkbox v-model="getMessageChecked"
              >我同意接收来自口袋宝交易打印的<span style="font-weight: 600"
                >短信</span
              ></el-checkbox
            > -->
            <p v-if="checked" class="addSend" @click="addSendBtn">添加发件人信息</p>
          </div>
        </div>

        <el-form
          ref="invoicelistFrom"
          :model="info_Invoice"
          label-position="left"
          inline-message
          @submit.native.prevent
          style="disply: flex; flex-direction: column"
        >
          <el-form-item class="info-head" style="border-bottom: 1px solid gray">
            <a name="infoInvoice">发货单发件人信息</a>
            <!-- <el-checkbox v-model="info_Invoice.multiSender" class="float-right"
              >同个店铺多个发件人</el-checkbox
            > -->
          </el-form-item>
          <el-form-item class="info-tb" style="width: 850px; margin-left: 24px">
            <div style="display: flex">
              <el-form-item label="发货标题" prop="title" style="display: flex">
                <el-input
                  size="mini"
                  v-model="info_Invoice.title"
                  class="info-input"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="经办人"
                prop="operator"
                style="display: flex; margin: auto 56px"
              >
                <el-input
                  size="mini"
                  v-model="info_Invoice.operator"
                  class="info-input"
                ></el-input>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item class="info-tb" style="margin-left: 24px">
            <div style="display: flex">
              <el-form-item label="店铺名称" prop="shop_name" style="display: flex">
                <el-input
                  size="mini"
                  v-model="info_Invoice.shop_name"
                  class="info-input"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="联系电话"
                prop="phone"
                style="display: flex; margin: auto 42px"
              >
                <el-input
                  size="mini"
                  v-model="info_Invoice.phone"
                  class="info-input"
                  :maxlength="11"
                ></el-input>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item class="info-tb" style="margin-left: 24px">
            <div style="display: flex">
              <el-form-item label="发件地址" prop="address" style="display: flex">
                <el-input
                  size="mini"
                  v-model="info_Invoice.address"
                  class="info-input"
                ></el-input>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="友情提示" class="info-tb" style="margin-left: 23px">
            <el-input
              type="textarea"
              :rows="4"
              v-model="info_Invoice.tips"
              style="width: 521px"
            >
            </el-input>
          </el-form-item>
          <el-form-item class="info-tb">
            <el-button
              type="primary"
              size="mini"
              @click="saveEpress(info_Invoice, 'editAddressInvoice', 'invoicelistFrom')"
              style="margin: auto 40px"
              >保存</el-button
            >
          </el-form-item>
        </el-form>

        <el-form ref="order_sort_form" :model="config_info" @submit.native.prevent>
          <el-form-item label="" class="info-head" style="border-bottom: 1px solid gray">
            <a name="order">订单显示顺序</a>
            <!-- <span>同一买家有多个订单的时候以第一个订单的时间为准</span> -->
          </el-form-item>
          <el-form-item class="info-tb">
            <el-radio-group v-model="config_info.order_sort" style="width: 600px">
              <el-radio style="margin-top: 10px" :label="1"
                >按订单<span style="color: blue">付款时间</span>，先付款的在前边</el-radio
              >
              <el-radio style="margin-top: 10px" :label="2"
                >按订单<span style="color: blue">付款时间</span>，后付款的在前边</el-radio
              >
              <el-radio style="margin-top: 10px" :label="3"
                >按订单<span style="color: #18c400">下单时间</span
                >，先下单的在前边</el-radio
              >
              <el-radio style="margin-top: 10px" :label="4"
                >按订单<span style="color: #18c400">下单时间</span
                >，后下单的在前边</el-radio
              >
              <br />
              <el-radio style="margin-top: 10px" :label="8">接收件地址省份排序</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="info-tb">
            <el-button type="primary" size="mini" @click="save_order_sort"
              >保存</el-button
            >
          </el-form-item>
        </el-form>

        <!-- <el-form :model="order" @submit.native.prevent class="hidden">
          <el-form-item
            label=""
            class="info-head "
            style="border-bottom: 1px solid gray"
          >
            <a name="order">快递单宝贝打印顺序</a>
            <span >快递单上的发货内容按以下方式排序</span>
          </el-form-item>
          <el-form-item  class="info-tb">
            <el-radio-group v-model="order.express_sort" style="display:flex;flex-direction:column; ">
              <el-radio :label="1">默认显示方式</el-radio> <span class="graytips" >按1688后台订单中商品的显示顺序排序</span>
              <el-radio :label="2">按货号排序</el-radio>
              <el-radio :label="3">按商品简称或标题的字母排序</el-radio>
              <el-radio :label="4">按同款商品同颜色排序</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item  class="info-tb">
            <el-button type="primary" size="mini" @click="saveEpress(order)">保存</el-button>
          </el-form-item>
        </el-form> -->

        <!-- <el-form :model="order" @submit.native.prevent class="hidden">
          <el-form-item
            label=""
            class="info-head "
            style="border-bottom: 1px solid gray"
          >
            <a name="order">发货单宝贝打印顺序</a>
            <span>发货单上的发货内容按以下方式排序</span>
          </el-form-item>
          <el-form-item  class="info-tb">
            
            <el-radio-group v-model="order.invoice_sort" style="display:flex;flex-direction:column; ">
              <el-radio :label="1">默认显示方式 <span class="graytips" >按1688后台订单中商品的显示顺序排序</span></el-radio>
              <el-radio :label="2">按货号排序</el-radio>
              <el-radio :label="3">按商品简称或标题的字母排序</el-radio>
              <el-radio :label="4">按同款商品同颜色排序</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item  class="info-tb">
            <el-button type="primary" size="mini" @click="saveEpress(order)">保存</el-button>
          </el-form-item>
        </el-form> -->

        <!-- <el-form :model="wordFilter" @submit.native.prevent>
          <el-form-item
            label=""
            class="info-head hidden"
            style="border-bottom: 1px solid gray"
          >
            <a name="wordFilter">基础过滤词设置</a>
          </el-form-item>
        </el-form> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { regular } from "@/utils/validate.js";
import provinceCity from "../../static/locationOption.json";
import { getConfig } from "@/api/setting.js";

export default {
  computed: {
    ...mapState({
      express_list: (state) => state.setting.express_list,
      info_Invoice: (state) => state.setting.invoice_list,
      config_info: (state) => state.setting.config_info,
    }),
  },
  data() {
    return {
      loadingCity: false,
      loadingDistrict: false,
      provinces: [],
      cities: [],
      districts: [],
      order: {
        order_sort: 4,
        express_sort: 1,
        invoice_sort: 1,
      },
      wordFilter: {},
      rule: {
        name: [
          { required: true, message: "请输入发件人姓名", trigger: "blur" },
          {
            min: 2,
            max: 11,
            message: "长度在 2 到 11 个字符",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入正确的电话号码", trigger: "blur" },
          {
            pattern: regular.mobile,
            message: regular.mobileMsg,
            trigger: "blur",
          },
        ],
        province: [
          {
            required: true,
            message: "请选择地址",
            trigger: ["blur", "change"],
          },
        ],
        city: [
          {
            required: true,
            message: "请选择地址",
            trigger: ["blur", "change"],
          },
        ],
        district: [
          { required: true, message: "请选择地址", trigger: "blur" },
          {
            // required: true,
            message: "请选择地址",
            trigger: ["blur", "change"],
          },
        ],
        address: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
        title: [
          { required: false, message: "请输入标题", trigger: "blur" },
          {
            min: 2,
            max: 11,
            message: "长度在 2 到 11 个字符",
            trigger: "blur",
          },
        ],
        operator: [
          { required: false, message: "请输入经办人姓名", trigger: "blur" },
          {
            min: 2,
            max: 11,
            message: "长度在 2 到 11 个字符",
            trigger: "blur",
          },
        ],
        shop_name: [
          { required: false, message: "请输入店铺名称", trigger: "blur" },
          {
            min: 2,
            max: 11,
            message: "长度在 2 到 11 个字符",
            trigger: "blur",
          },
        ],
      },
      invoicerule: {},
      checked: false,
      getMessageChecked: false,
    };
  },

  methods: {
    provinceLoad() {
      this.provinces = provinceCity[0];
    },
    provinceChanged(code,index) {
      console.log(code,index,'---code,index')
      if (code != "") {
        var str = "0," + code;
        this.loadingCity = true;
        this.cities = provinceCity[str];
        this.express_list[index].province_code = code;
        this.express_list[index].province = provinceCity[0][code];
        this.loadingCity = false;
        this.nullcity(index);
        this.nulldistrict(index);
      }
    },
    cityLoad(index) {
      var str = "0," + this.express_list[index].province_code;
      this.cities = provinceCity[str];
    },
    cityChanged(code,index) {
      if (code != "") {
        var str = "0," + this.express_list[index].province_code + "," + code;
        this.loadingDistrict = true;
        this.districts = provinceCity[str];
        this.express_list[index].city_code = code;
        this.express_list[index].city = this.cities[code];
        this.loadingDistrict = false;
        this.nulldistrict(index);
      }
    },
    districtLoad(index) {
      var str = "0," + this.express_list[index].province_code + "," + this.express_list[index].city_code;
      this.districts = provinceCity[str];
    },
    districtChanged(code,index) {
      if (code != "") {
        this.loadingDistrict = true;
        var str = "0," + this.express_list[index].province_code + "," + this.express_list[index].city_code;
        this.express_list[index].district = provinceCity[str][code];
        this.express_list[index].district_code = code;
        this.express_list[index].post_code = code;
        this.loadingDistrict = false;
      }
    },
    nullcity(index) {
      this.express_list[index].city_code = "";
      this.express_list[index].city = "";
    },
    nulldistrict(index) {
      this.express_list[index].district = "";
      this.express_list[index].district_code = "";
    },
    saveEpress(params, api, formName) {
      var url = "setting/" + api;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.info_Invoice.phone.length>=1 && this.info_Invoice.phone.length<11){
            this.$message({ message: "联系电话不能小于11位", type: "error" });
            return false
          }
          this.$store.dispatch(url, params).then((res) => {
            if (res.code == 0) {
              this.$message({ message: "保存成功", type: "success" });
            } else {
              // this.$message({ message: res.message, type: "success" });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 保存订单显示顺序
    save_order_sort(){
      this.$store.dispatch("setting/editConfig", {order_sort: this.config_info.order_sort}).then((res) => {
        if (res.code == 0) {
          this.$message({ message: "保存成功", type: "success" });
        } else {
          this.$message({ message: res.message, type: "error" });
        }
      });
    },
    // 保存发件人列表
    saveExpressSenderList(){
      let from_verify_error = false
      for(let i in this.express_list){
        let refs_name = "expresslistFrom_"+i
        this.$refs[refs_name][0].validate((valid) => {
          if (!valid) {
            from_verify_error = true
          }
        });
      }
      if(from_verify_error){
        return false
      }else{
        this.$store.dispatch("setting/editAddressExpress", this.express_list).then((res) => {
            if (res.code == 0) {
              this.$message({ message: "保存成功", type: "success" });
            } else {
              this.$message({ message: res.message, type: "success" });
            }
          });
      }
    },
    // 获取配置信息
    async getSetting() {
      let that = this;
      await this.$store.dispatch("setting/getConfig").then((res) => {
        // that.info_Invoice=that.info_Invoice[0]
      });
    },
    addSendBtn() {
      this.express_list.push({
        address: "",
        city: "",
        city_code: "",
        ctime: "",
        district: "",
        district_code: "",
        id: "",
        is_del: "",
        mtime: "",
        name: "",
        phone: "",
        post_code: "",
        province: "",
        province_code: "",
        shop_id: "",
        sign_name: "",
      });
    },
    deleteBtn(index) {
      if(this.express_list.length>1){
        this.express_list.splice(index, 1);
      }else{
        this.$message({ message:"请至少保留一个快递单发件人", type: "warning" });
      }
    },
  },
  created() {
    this.getSetting();
  },
  saveOrderSort(){
    
  }
};
</script>

<style scoped>
.content {
  width: 100%;
  height: 950px;
  display: flex;
  justify-content: center;
  align-content: center;
  background: linear-gradient(to bottom, #e6e0d0 0%, #ffffff 100%);
}
.main-content {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  border-radius: 4px;
  background-color: #ffffff;
  width: 95%;
  /* height: 90%; */
}
.top-href {
  background-color: #fcf6d4;
  width: 80%;
  height: 35px;
  display: flex;
  justify-content: center;
  margin: 20px auto;
  border: 1px solid #e6a23c;
  border-radius: 4px;
  padding: auto 12px;
  font-size: 14px;
  line-height: 35px;
}
.top-href a {
  margin: auto 50px;
  color: blue;
}
.info-form {
  width: 80%;
  margin: 20px auto;
}
.info-head a {
  font-size: 20px;
  color: black;
}
.info-head span {
  color: gray;
  margin-left: 20px;
}
.float-right {
  float: right;
}
.graytips {
  margin: auto 20px;
  color: gray;
}
.info-input {
  width: 205px;
}
.info-tb {
  display: flex;
  margin: auto 37px;
  /* margin-left: 37px; */
}
.select-width {
  width: 163px;
}
.hidden {
  display: none;
}
.addSend {
  color: #3366cc;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 165px;
}
.numClass {
  position: absolute;
  top: 8px;
  left: 10px;
  color: #f56c6c;
  font-weight: 600;
}
.has_red_icon{
  position: relative;
  margin-left: -14px !important;
}
.has_red_icon:before{
  content: '*';
  color: #F56C6C;
  margin: 9px 5px 0 0;
}
</style>
